<template>
  <!-- 登录页 -->
  <div class="user-login">
    <div class="login-form">
      <el-form
        class="form form2"
        :model="regisform"
        label-position="left"
        :rules="regisrules"
        ref="form2"
        label-width="100px"
      >
        <el-form-item prop="telephone" label="手机号码">
          <el-input
            v-model="regisform.telephone"
            placeholder="请输入注册手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            type="password"
            v-model="regisform.password"
            show-password
            placeholder="请输入密码"
            minlength="8"
            maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password2" label="确认密码">
          <el-input
            type="password"
            v-model="regisform.password2"
            show-password
            placeholder="请确认密码"
            minlength="8"
            maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code" label="图片验证码">
          <el-input
            style="width: 60%"
            v-model="regisform.code"
            placeholder="图片验证码"
          ></el-input>
          <img
            style="vertical-align: middle"
            class="btn"
            :src="imgSrc"
            @click="initPicCode"
            alt=""
          />
        </el-form-item>
        <el-form-item prop="checkcode" label="短信验证码">
          <el-input
            style="width: 60%"
            v-model="regisform.checkcode"
            placeholder="请输入短信验证码"
          ></el-input>
          <el-button
            class="btn"
            type="primary"
            @click="getSmsCode"
            :disabled="flag"
            style="padding: 10px"
            >{{ flag ? `（${current}） s` : "获取验证码" }}</el-button
          >
        </el-form-item>

        <el-form-item label="用户类型" prop="userType">
          <el-radio-group v-model="regisform.userType">
            <el-radio :label="1">注册为农户</el-radio>
            <el-radio :label="2"
              >注册为新型经营主体 <br /><span
                style="
                  display: inline-block;
                  padding-left: 22px;
                  font-size: 12px;
                "
              >
                (家庭农场、合作社、农业企业等)</span
              ></el-radio
            >
            <el-radio :label="3">注册为集体经济组织</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button
          style="width: 80%; margin-top: 10px auto"
          type="primary"
          @click="submitRegister"
          >注册</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getPicCode, getSmsCode, register } from "../../api/loginAndRegister";
import { telephoneValidator } from "../../js/utils/rules";
export default {
  data() {
    return {
      imgSrc: "",
      imageKey: "",
      regisform: {
        userType: 1,
        password: "",
      },
      regisrules: {
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { trigger: "blur", validator: telephoneValidator },
        ],
        userType: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
        ],
        password2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{7,17}$/,
            message: "以字母开头，长度在8~18之间，只能包含字母和数字",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (
                this.regisform.password &&
                value !== this.regisform.password
              ) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
          },
        ],
        code: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        checkcode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      flag: false,
      max: 60,
      current: 60,
      decreseInterval: null,
    };
  },
  created() {
    this.initPicCode();
  },
  methods: {
    async initPicCode() {
      // 加载图片验证码
      let res = await getPicCode();
      let { code, data } = res;
      if (code == 0) {
        this.imgSrc = data.imageBase64;
        this.imageKey = data.imageKey;
      }
    },
    async getSmsCode() {
      // 获取手机验证码
      if (this.regisform.telephone) {
        let res = await getSmsCode(this.regisform.telephone);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current <= this.max && this.current > 0) {
              this.current--;
              this.flag = true;
            } else if (this.current <= 0) {
              this.current = 0;
              this.flag = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
    submitRegister() {
      // 提交立即注册
      this.$refs.form2.validate(async (valid) => {
        if (valid) {
          let params = {
            phone: this.regisform.telephone,
            imageCode: this.regisform.code,
            smsCode: this.regisform.checkcode,
            password: this.regisform.password,
            repwd: this.regisform.password2,
            imageKey: this.imageKey, // 请求生成图形验证码后的参数
            registerType: this.regisform.userType,
          };
          let res = await register(params);
          let { code, msg, data } = res;
          if (code == 0) {
            localStorage.setItem("loginStep1UserId", data.userId);
            if (this.regisform.userType == 1) {
              this.$router.push({ name: "register1" });
            } else if (this.regisform.userType == 2) {
              this.$router.push({ name: "register2" });
            } else {
              this.$router.push({ name: "register3" });
            }
            this.$message.success("注册成功，实名认证后即可登录使用！");
          } else {
            this.initPicCode();
            this.$message.error(msg);
            // console.log("5555",msg);
          }
        }
      });
    },
    goLogin() {
      // 已有帐号去登录
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
.user-login {
  height: 651px;
  width: 100%;
  position: relative;
  z-index: 3;
  background-image: url("../../assets/imgs/login-bg.png");
  background-position: 0 45%;
  background-repeat: no-repeat;
  padding-top: 92px;
  background-size: 100% auto;
  .login-form {
    // width: 431px;
    // height: 414px;
    width: 400px;
    padding: 40px 53px;
    background-color: #ffffff;
    border-radius: 10px;
    // position: absolute;
    // top: 60px;
    // right: 387px;
    // top: 92px;
    // right: 779px;
    // right: 40%;
    margin: 0px auto;
    .tabs {
      width: 100%;
      height: 42px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tab-item {
        width: 215px;
        height: 40px;
        line-height: 20px;
        margin-right: 35px;
        font-size: 18px;
      }
      .active {
        color: #00a0e9;
        border-bottom: 2px solid #00a0e9;
      }
    }
    .form {
      margin-top: 32px;
      .el-form-item {
        margin-bottom: 33px;
      }
      .btn {
        width: 100px;
        height: 40px;
        margin-left: 15px;
      }
    }
    .register {
      color: #00a0e9;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
    .form2 {
      .el-form-item {
        margin-bottom: 20px;
      }
    }
  }

  .register {
    color: #00a0e9;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
}
/deep/.el-radio {
  margin-bottom: 12px !important;
}
</style>

<style lang="less">
.my-height {
  .el-input__inner {
    height: 48px;
  }
}
</style>
